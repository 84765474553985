import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  Inject,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ModelFormGroup } from '@core/types/helper-types';
import {
  AvatarComponent,
  COLLABORATOR_TYPES,
  CreateProjectPayloadNew,
  FilterPipe,
  FlexDirective,
  FlexGapDirective,
  FlexLayoutAlignDirective,
  FormControlItem,
  FormUtility,
  INameValidation,
  InputMessageComponent,
  MainProjectModel,
  MainProjectType,
  PmSelectInputComponent,
  PmTextInputComponent,
  ProjectService,
  ProjectState,
  ProjectStateNew,
  SvgIconDirective,
  TestIdDirective,
  TestIds,
  UserModel,
} from 'ngx-q360-lib';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Actions, ofActionSuccessful, select, Store } from '@ngxs/store';
import { OrganisationSelectors } from '@app/store/organisation/organisation.selectors';
import { ContractUtils } from '@core/utils/contract.utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatSlideToggle, MatSlideToggleChange } from '@angular/material/slide-toggle';
import { CreateMainProject, CreateProject } from '@app/store/project/project.actions';
import { ProjectSelectors } from '@app/store/project/projects.selectors';
import { UserSelectors } from '@app/store/user/user.selectors';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgStyle } from '@angular/common';
import { MatDivider } from '@angular/material/divider';
import { DialogWrapperComponent } from '../../components/dialog-wrapper/dialog-wrapper.component';

interface DialogData {
  selectedMainProject?: MainProjectModel;
}

@Component({
  selector: 'app-create-project-dialog-new',
  templateUrl: './create-project-dialog.component.html',
  styleUrls: ['./create-project-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DialogWrapperComponent,
    TestIdDirective,
    FormsModule,
    FlexGapDirective,
    ReactiveFormsModule,
    PmTextInputComponent,
    InputMessageComponent,
    MatDivider,
    AvatarComponent,
    PmSelectInputComponent,
    NgStyle,
    ExtendedModule,
    MatSlideToggle,
    FlexLayoutAlignDirective,
    FilterPipe,
    SvgIconDirective,
    FlexDirective,
  ],
})
export class CreateProjectDialogComponent implements OnInit {
  protected readonly testIds = TestIds;
  private destroyRef = inject(DestroyRef);
  protected readonly mainProjectType = MainProjectType;
  protected readonly collaboratorTypes = COLLABORATOR_TYPES;

  myOrg = select(OrganisationSelectors.organisation);
  sasUrl = select(UserSelectors.sasRealUrlForImages);
  projectsBasic = select(ProjectSelectors.myProjectsBasic);
  existingProjectsCodes = computed(() =>
    this.projectsBasic()
      .filter((p) => p.mainProject.id === this.data.selectedMainProject?.id)
      .map((p) => p.code),
  );

  fb = inject(FormBuilder);
  matDialogRef = inject(MatDialogRef<CreateProjectDialogComponent>);
  matDialog = inject(MatDialog);
  store = inject(Store);
  projectService = inject(ProjectService);
  actions = inject(Actions);

  contractType = new FormControl<string | null>(null);
  contractConditionTitle = new FormControl<string | null>(null);
  shortCodeNameValidation: INameValidation = {
    listOfNames: this.existingProjectsCodes(),
    editName: '',
  };

  form: ModelFormGroup<CreateProjectPayloadNew> = this.fb.group({
    name: ['', [FormUtility.requiredValidator(), FormUtility.maxLengthValidator(100)]],
    code: [
      '',
      [
        FormUtility.requiredValidator(),
        FormUtility.minLengthValidator(2),
        FormUtility.maxLengthValidator(5),
        FormUtility.uniqueName(
          this.shortCodeNameValidation,
          'This main project group already has a project with the same short name. Please choose another short name.',
        ),
      ],
    ],
    mainProjectId: [this.data.selectedMainProject?.id || '', [FormUtility.requiredValidator()]],
    projectLeaderId: [null],
    collaboratorType: [null, this.myOrgIsCoOwner && !this.projectHasOwner ? [FormUtility.requiredValidator()] : []],
    contractType: [null],
    projectState: [ProjectState.InProgress],
    state: [ProjectStateNew.InProgress],
  }) as ModelFormGroup<CreateProjectPayloadNew>;

  contractTypes: FormControlItem[] = [];
  users: UserModel[] = [];

  @ViewChild('collaborating') collaborating!: MatSlideToggleChange;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit() {
    this.contractType.disable();

    // projectLeaderId is required if owner is known and my org is co-owner
    if (this.data.selectedMainProject && this.projectHasOwner && this.myOrgIsCoOwner) {
      this.controls.projectLeaderId.setValidators([FormUtility.requiredValidator()]);
      this.projectService
        .getOwnersUsers(this.data.selectedMainProject.id, {
          pageSize: 1000,
          page: 0,
        })
        .subscribe((result) => {
          this.users = result.items;
        });
    }

    // preselect main project if only one
    this.actions
      .pipe(ofActionSuccessful(CreateMainProject))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        const mainProjects = this.store.selectSnapshot(ProjectSelectors.mainProjects);
        if (mainProjects.length === 1) {
          this.controls.mainProjectId.setValue(mainProjects[0].id);
        }
      });

    this.controls.name.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
      this.controls.code.setValue(this.generateCode(value), { emitEvent: false });
      this.controls.code.markAsTouched();
      this.controls.code.updateValueAndValidity();
    });

    // on changing main project, update required fields
    this.controls.mainProjectId.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
      this.data.selectedMainProject = this.store
        .selectSnapshot(ProjectSelectors.mainProjects)
        .find((p) => p.id === value);
      if (!this.projectHasOwner) {
        this.controls.collaboratorType.setValidators([FormUtility.requiredValidator()]);
      } else {
        this.controls.collaboratorType.setValidators([]);
      }
      this.controls.collaboratorType.updateValueAndValidity();
    });

    // if collaboratorType is selected, contractType must be selected too
    this.controls.collaboratorType.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
      if (value) {
        this.contractType.enable();
        this.contractTypes = ContractUtils.getContractTypesByPartners('Client', value, 'en');

        this.controls.contractType.setValidators([FormUtility.requiredValidator()]);
        this.controls.contractType.updateValueAndValidity();
        this.contractType.setValidators([FormUtility.requiredValidator()]);
        this.contractType.updateValueAndValidity();
      } else {
        this.contractType.disable();

        this.controls.contractType.clearValidators();
        this.controls.contractType.updateValueAndValidity();

        this.contractType.clearValidators();
        this.contractType.updateValueAndValidity();
      }
    });

    // if contract conditions are not listed, user can manually input them, field will be required
    this.contractType.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
      this.controls.contractType.setValue(value);
      if (value === 'unknown') {
        this.contractConditionTitle.setValidators([FormUtility.requiredValidator()]);
        this.contractConditionTitle.updateValueAndValidity();
      } else {
        this.contractConditionTitle.reset();
        this.contractConditionTitle.clearValidators();
        this.contractConditionTitle.updateValueAndValidity();
      }
    });

    this.contractConditionTitle.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
      if (this.collaborating && this.collaborating.checked && this.contractType.value === 'unknown') {
        this.controls.contractType.setValue(value);
      }
    });
  }

  get controls() {
    return this.form.controls;
  }

  get projectHasOwner(): boolean {
    return !!this.data.selectedMainProject?.ownerOrganizationId;
  }

  get myOrgIsCoOwner() {
    return this.data.selectedMainProject?.coOwnerOrganizationId === this.myOrg()?.id;
  }

  private generateCode(value: string): string {
    const words = value.split(' ').filter((el) => el?.length);
    if (words.length === 1) {
      return words[0].substring(0, 4).toUpperCase();
    }
    return words
      .slice(0, 4)
      .map((s) => s[0].toUpperCase())
      .join('');
  }

  onCancelClick() {
    this.matDialogRef.close();
  }

  onSaveClick() {
    this.matDialogRef.close(true);
    this.store.dispatch(new CreateProject(this.form.getRawValue()));
  }

  onCollaborationChange(event: MatSlideToggleChange) {
    if (event.checked) {
      this.controls.collaboratorType.setValidators([FormUtility.requiredValidator()]);
      this.controls.collaboratorType.updateValueAndValidity();
    } else {
      this.controls.collaboratorType.reset();
      this.controls.collaboratorType.clearValidators();
      this.controls.collaboratorType.updateValueAndValidity();
      this.contractType.reset();
      this.contractConditionTitle.reset();
    }
  }

  get formInvalid() {
    return this.form.invalid || this.contractConditionTitle.invalid;
  }
}
