import {
  Directive,
  ElementRef,
  Input,
  ViewContainerRef,
  ComponentRef,
  OnInit,
  HostListener,
  inject,
  DestroyRef,
} from '@angular/core';
import { UserOnboardingGuideComponent } from '../components/user-onboarding-guide/user-onboarding-guide.component';
import { Actions, Store, ofActionDispatched } from '@ngxs/store';
import { PublishUserOnboardingSetting, SetUserOnboardingSetting } from '@app/store/user/user.actions';
import { UserOnboardingSettingsEnum } from 'ngx-q360-lib';
import { UserSettingsUtil } from '@app/app-core/utils/user-settings.utils';
import { UserSelectors } from '@app/store/user/user.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[userOnboardingGuideType]',
  standalone: true,
})
export class UserOnboardingGuideDirective implements OnInit {
  @Input() userOnboardingGuideType!: UserOnboardingSettingsEnum | UserOnboardingSettingsEnum[];
  @Input() userOnboardingGuidePosition: 'bottom' | 'right' = 'bottom';
  componentRef!: ComponentRef<UserOnboardingGuideComponent>;
  selectedType!: UserOnboardingSettingsEnum | null;
  isShowed = false;
  private destroyRef = inject(DestroyRef);

  constructor(
    private parentElement: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private actions$: Actions,
    private store: Store,
  ) {
    this.actions$
      .pipe(ofActionDispatched(PublishUserOnboardingSetting))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((payload) => {
        if (!payload.setting) {
          return;
        }
        this.selectedType = null;
        this.isShowed = false;
        const requestedType = Array.isArray(this.userOnboardingGuideType)
          ? this.userOnboardingGuideType.find((s) => s === payload.setting.type)
          : this.userOnboardingGuideType;

        if (requestedType === payload.setting.type) {
          this.selectedType = payload.setting.type;
          payload.setting.show ? this.showComponent() : this.hideComponent(false);
        }
      });
  }

  ngOnInit(): void {
    this.collaborateWithYourTeamSetting();
    this.organizeYourProjectsSetting();
  }

  @HostListener('document:click', ['$event'])
  clickout(event: any): void {
    const nativeElement = this.componentRef?.location.nativeElement;
    if (this.isShowed && !nativeElement?.contains(event.target)) {
      this.hideComponent();
    }
  }

  toggleShow() {
    setTimeout(() => {
      this.isShowed = !this.isShowed;
    }, 100);
  }

  showComponent() {
    this.toggleShow();
    this.componentRef = this.viewContainerRef.createComponent(UserOnboardingGuideComponent);
    const instance = this.componentRef.instance;
    instance.userOnboardingSettingType = UserSettingsUtil.getOnboardingSettingByType(this.selectedType!);

    const rect = this.parentElement.nativeElement.getBoundingClientRect();
    const nativeElement = this.componentRef.location.nativeElement;
    nativeElement.style.position = 'absolute';
    nativeElement.style.zIndex = '999';

    const windowMidPoint = window.innerWidth / 2;
    const elementMidPoint = rect.left + rect.width / 2;

    if (this.userOnboardingGuidePosition === 'right') {
      this.componentRef.instance.position = 'left';
      nativeElement.style.left = `${rect.width}px`;
      const top = nativeElement.offsetHeight / 2 + 16;
      nativeElement.style.top = `-${top}px`;
    } else if (elementMidPoint < windowMidPoint) {
      this.componentRef.instance.position = 'left-top';
      nativeElement.style.left = `-26px`;
      nativeElement.style.top = `${rect.height}px`;
    } else {
      this.componentRef.instance.position = 'right-top';
      nativeElement.style.right = `-22px`;
      nativeElement.style.top = `${rect.height}px`;
    }

    this.parentElement.nativeElement.style.position = 'relative';
    this.parentElement.nativeElement.appendChild(nativeElement);
  }
  hideComponent(dispatchAction = true) {
    if (this.componentRef) {
      this.componentRef.destroy();
      dispatchAction && this.store.dispatch(new SetUserOnboardingSetting(this.selectedType!, false));
      setTimeout(() => {
        this.toggleShow();
      }, 100);
    }
  }

  // H E L P E R S
  collaborateWithYourTeamSetting() {
    if (this.userOnboardingGuideType === UserOnboardingSettingsEnum.COLLABORATE_WITH_YOUR_TEAM) {
      const collaborateWithYourTeam = this.store.selectSnapshot(UserSelectors.onboardingSettingById)(
        UserOnboardingSettingsEnum.COLLABORATE_WITH_YOUR_TEAM,
      );
      if (collaborateWithYourTeam?.show) {
        this.selectedType = collaborateWithYourTeam.type;
        this.showComponent();
      }
    }
  }
  organizeYourProjectsSetting() {
    if (this.userOnboardingGuideType === UserOnboardingSettingsEnum.ORGANIZE_YOUR_PROJECTS) {
      const organiseYourProjects = this.store.selectSnapshot(UserSelectors.onboardingSettingById)(
        UserOnboardingSettingsEnum.ORGANIZE_YOUR_PROJECTS,
      );

      if (organiseYourProjects?.show) {
        this.selectedType = UserOnboardingSettingsEnum.ORGANIZE_YOUR_PROJECTS;
        this.showComponent();
      }
    }
  }
}
