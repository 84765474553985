<div class="p-8 mt-4 position-relative">
  <div class="tooltip-container">
    <div class="tooltip-anchor" [class]="position"></div>
    <div>
      <div class="header" flexLayoutAlign="space-between center">
        <div class="labels-label-m greyscale-off-white all-caps">{{ userOnboardingSettingType.title }}</div>
        <svg
          class="mb-4 greyscale-off-white"
          svgIcon="basic-close"
          svgWidth="24"
          [svgPointer]="true"
          (click)="close($event)"
        />
      </div>
      <div class="labels-label-m greyscale-off-white mt-16" [innerHtml]="userOnboardingSettingType.subtitle"></div>
      <div
        class="labels-label-m greyscale-off-white mt-16"
        [innerHtml]="userOnboardingSettingType.description ?? ''"
      ></div>
    </div>
  </div>
</div>
