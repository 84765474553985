import { Component, Input } from '@angular/core';
import { UserOnboardingSettingTooltipModel } from '@app/app-core/utils/user-settings.utils';
import { SetUserOnboardingSetting } from '@app/store/user/user.actions';
import { Store } from '@ngxs/store';

import { FlexLayoutAlignDirective, SvgIconDirective } from 'ngx-q360-lib';

@Component({
  selector: 'app-user-onboarding-guide',
  templateUrl: './user-onboarding-guide.component.html',
  styleUrl: './user-onboarding-guide.component.scss',
  standalone: true,
  imports: [FlexLayoutAlignDirective, SvgIconDirective],
})
export class UserOnboardingGuideComponent {
  @Input() userOnboardingSettingType!: UserOnboardingSettingTooltipModel;
  @Input() position: 'left-top' | 'right-top' | 'left' | 'center' = 'right-top';

  constructor(private store: Store) {}

  close(event: Event) {
    event.stopPropagation();
    this.store.dispatch(new SetUserOnboardingSetting(this.userOnboardingSettingType.type, false));
  }
}
